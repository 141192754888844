import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/database'
import config from '../config'
firebase.initializeApp({
    apiKey: "AIzaSyCW6mossb3TLr8MNIgQ57ChXk5jcB7md70",
    authDomain: "palata-edu.firebaseapp.com",
    projectId: "palata-edu",
    storageBucket: "palata-edu.appspot.com",
    messagingSenderId: "899173928257",
    appId: "1:899173928257:web:2b84364052a30f8f57e5c0"
  })
firebase.analytics()

export default firebase

export const { auth } = firebase
export const realtime = firebase.database
export const { TIMESTAMP } = realtime.ServerValue
