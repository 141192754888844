<template>
  <div>
    <nav
      class="st-nav navbar main-nav navigation transparent dark-link"
      id="main-nav"
    >
      <div class="container mt-2 mb-2" style="padding-left: 0px">
        <ul class="st-nav-menu nav navbar-nav">
          <li class="st-nav-section st-nav-primary nav-item">
            <LogoSmall></LogoSmall>
          </li>
          <li
            class="st-nav-section st-nav-secondary nav-item"
            style="border-left-style: solid; border-left-width: 1px"
          >
            <a class="b nav-link" @click="logout()">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.331 2.02148C13.7102 2.02148 15.6521 3.89542 15.7611 6.24772L15.766 6.45648V7.38948C15.766 7.8037 15.4302 8.13948 15.016 8.13948C14.6363 8.13948 14.3225 7.85733 14.2728 7.49125L14.266 7.38948V6.45648C14.266 4.89358 13.044 3.61575 11.5034 3.52647L11.331 3.52148H6.45597C4.89392 3.52148 3.61623 4.74362 3.52696 6.28406L3.52197 6.45648V17.5865C3.52197 19.1493 4.74388 20.4272 6.28363 20.5165L6.45597 20.5215H11.341C12.8983 20.5215 14.172 19.3039 14.261 17.7693L14.266 17.5975V16.6545C14.266 16.2403 14.6018 15.9045 15.016 15.9045C15.3957 15.9045 15.7095 16.1866 15.7591 16.5527L15.766 16.6545V17.5975C15.766 19.9687 13.8992 21.9046 11.5553 22.0164L11.341 22.0215H6.45597C4.07753 22.0215 2.13581 20.1474 2.0268 17.7952L2.02197 17.5865V6.45648C2.02197 4.07743 3.89573 2.13535 6.24728 2.02631L6.45597 2.02148H11.331ZM22.3282 11.4797C22.4707 11.6162 22.5595 11.8084 22.5595 12.0214C22.5595 12.2365 22.4689 12.4304 22.3239 12.5672L19.4104 15.4687C19.1169 15.761 18.642 15.76 18.3498 15.4665C18.084 15.1997 18.0607 14.783 18.2791 14.4898L18.3519 14.4059L19.992 12.7714H9.76847C9.35426 12.7714 9.01847 12.4356 9.01847 12.0214C9.01847 11.6417 9.30063 11.3279 9.6667 11.2782L9.76847 11.2714H19.9937L18.352 9.63779C18.0852 9.37212 18.06 8.95551 18.2772 8.66142L18.3497 8.57714C18.6153 8.31028 19.0319 8.28514 19.326 8.50234L19.4103 8.57478L22.3282 11.4797Z"
                      fill="#000950"/>
              </svg>
              Выход
            </a>
            <!-- <button type="button" class="btn btn-md  btn-primary btn-outlined" @click='page("login")'><i class="fas fa-sign-in-alt d-none d-md-inline mr-md-0 mr-lg-2"></i>Личный  кабинет</button> -->
          </li>

        </ul>
      </div>
      <li class="" style="background-color:rgba(4,162,124, 1); width: 100%">
        <div
          class="container st-nav-section st-nav-primary nav-item pa-0"
          style="justify-content: left"
        >

          <a class="st-root-link nav-link" href="/org/">
            <b>Образовательные группы</b>
          </a>

          <a class="st-root-link nav-link" href="/orgsettings/">
            Настройки организации
          </a>

          <a class="st-root-link nav-link" href="/listrequests/">
            Комната ожидания
          </a>
          <a class="st-root-link nav-link" href="/org-docs">
            Документы
          </a>
          <router-link :to="{ name: 'archive' }" class="st-root-link nav-link">Архив</router-link>
          <router-link :to="{ name: 'payments_org' }" class="st-root-link nav-link">Счет на оплату</router-link>
        </div>
      </li>
      <li class="" style="background-color: #fff; width: 100%">
        <div
          class="container st-nav-section st-nav-primary nav-item pa-0"
          style="justify-content: left"
        >
          <!-- <a
            class="st-root-link b nav-link"
            href="#"
            v-scroll-to="'#future-nalog'"
            >
            <svg width="16" height="16"  class="mb-05" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.10489 13.8476V11.8031C6.10488 11.2831 6.52883 10.8605 7.05409 10.857H8.97814C9.50591 10.857 9.93375 11.2806 9.93375 11.8031V11.8031V13.8539C9.93363 14.2954 10.2896 14.6563 10.7354 14.6666H12.0181C13.2968 14.6666 14.3334 13.6404 14.3334 12.3745V12.3745V6.55854C14.3266 6.06053 14.0904 5.59288 13.6921 5.28867L9.30523 1.79018C8.5367 1.18102 7.44422 1.18102 6.67569 1.79018L2.3081 5.29502C1.90825 5.59799 1.67167 6.06642 1.66675 6.56489V12.3745C1.66675 13.6404 2.70333 14.6666 3.98202 14.6666H5.26472C5.72165 14.6666 6.09206 14.2999 6.09206 13.8476V13.8476" stroke="#333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
              Главная
          </a> -->

          <!-- <a
           class="st-root-link b nav-link"
           href="/orgsettings/"
           >
           <svg width="17" height="17" class="mb-05" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
           <circle cx="8.5" cy="8.5" r="8" fill="white" stroke="#333"/>
           <ellipse cx="8.30121" cy="6.27015" rx="2.25629" ry="2.25629" stroke="#333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
           <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7223 11.6645C4.72169 11.5059 4.75717 11.3492 4.82604 11.2063C5.04216 10.7741 5.65161 10.545 6.15733 10.4413C6.52206 10.3634 6.89182 10.3115 7.26387 10.2857C7.95269 10.2252 8.64548 10.2252 9.3343 10.2857C9.70631 10.3117 10.0761 10.3637 10.4408 10.4413C10.9466 10.545 11.556 10.7525 11.7721 11.2063C11.9106 11.4976 11.9106 11.8358 11.7721 12.127C11.556 12.5809 10.9466 12.7883 10.4408 12.8878C10.0765 12.9688 9.70664 13.0223 9.3343 13.0477C8.77366 13.0952 8.2104 13.1039 7.64856 13.0736C7.51889 13.0736 7.39354 13.0736 7.26387 13.0477C6.89292 13.0226 6.52444 12.9691 6.16166 12.8878C5.65161 12.7883 5.04648 12.5809 4.82604 12.127C4.75752 11.9825 4.72208 11.8245 4.7223 11.6645Z" stroke="#333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
           Настройки организации
         </a> -->


        </div>
      </li>

      <div class="st-dropdown-root">
        <div class="st-dropdown-bg">
          <div class="st-alt-bg"></div>
        </div>
        <div class="st-dropdown-arrow"></div>
        <div class="st-dropdown-container">
          <div class="st-dropdown-section" data-dropdown="blocks">
            <div class="st-dropdown-content">
              <div class="st-dropdown-content-group">
                <div class="row">
                  <div class="col mr-4">
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/call-to-action.html"
                    >Call to actions</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/contact.html"
                    >Contact</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/counter.html"
                    >Counters</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/faqs.html"
                    >FAQs</a
                    >
                  </div>
                  <div class="col mr-4">
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/footer.html"
                    >Footers</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/form.html"
                    >Forms</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/navbar.html"
                    >Navbar</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/navigation.html"
                    >Navigation</a
                    >
                  </div>
                  <div class="col">
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/pricing.html"
                    >Pricing</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/slider.html"
                    >Sliders</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/team.html"
                    >Team</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blocks/testimonial.html"
                    >Testimonials</a
                    >
                  </div>
                </div>
              </div>
              <div class="st-dropdown-content-group">
                <h3 class="link-title">
                  <i class="fas fa-long-arrow-alt-right icon"></i> Coming soon
                </h3>
                <div class="ml-5">
                  <span class="dropdown-item text-secondary">Dividers </span
                  ><span class="dropdown-item text-secondary">Gallery </span
                ><span class="dropdown-item text-secondary">Screenshots</span>
                </div>
              </div>
            </div>
          </div>
          <div class="st-dropdown-section" data-dropdown="pages">
            <div class="st-dropdown-content">
              <div class="st-dropdown-content-group">
                <div class="mb-4">
                  <h3 class="text-darker light text-nowrap">
                    <span class="bold regular">Useful pages</span> you'll need
                  </h3>
                  <p class="text-secondary mt-0">Get a complete design stack</p>
                </div>
                <div class="row">
                  <div class="col">
                    <ul class="mr-4">
                      <li>
                        <h4 class="text-uppercase regular">Error</h4>
                      </li>
                      <li><a target="_blank" href="403.html">403 Error</a></li>
                      <li><a target="_blank" href="404.html">404 Error</a></li>
                      <li><a target="_blank" href="405.html">405 Error</a></li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul class="mr-4">
                      <li>
                        <h4 class="text-uppercase regular">User</h4>
                      </li>
                      <li><a target="_blank" href="login.html">Login</a></li>
                      <li>
                        <a target="_blank" href="register.html">Register</a>
                      </li>
                      <li><a target="_blank" href="forgot.html">Forgot</a></li>
                    </ul>
                  </div>
                  <div class="col">
                    <ul>
                      <li>
                        <h4 class="text-uppercase regular">Extra</h4>
                      </li>
                      <li>
                        <a target="_blank" href="pricing.html">Pricing</a>
                      </li>
                      <li><a target="_blank" href="terms.html">Terms</a></li>
                      <li><a target="_blank" href="faqs.html">FAQ</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="st-dropdown-content-group">
                <a class="dropdown-item bold" href="about.html"
                ><i class="far fa-building icon"></i> About </a
                ><a class="dropdown-item bold" href="contact.html"
              ><i class="far fa-envelope icon"></i> Contact </a
              ><a class="dropdown-item bold" href="pricing.html"
              ><i class="fas fa-hand-holding-usd icon"></i> Pricing</a
              >
              </div>
            </div>
          </div>
          <div class="st-dropdown-section" data-dropdown="components">
            <div class="st-dropdown-content">
              <div class="st-dropdown-content-group">
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="components/color.html"
                >
                  <div class="media mb-4">
                    <i
                      class="fas fa-palette mr-2 bg-primary rounded-circle icon-md text-contrast center-flex"
                    ></i>
                    <div class="media-body">
                      <h3 class="link-title m-0">Colors</h3>
                      <p class="m-0 text-secondary">
                        Get to know DashCore color options
                      </p>
                    </div>
                  </div>
                </a
                ><a
                class="dropdown-item"
                target="_blank"
                href="components/accordion.html"
              >
                <div class="media mb-4">
                  <i
                    class="fas fa-bars mr-2 bg-primary rounded-circle icon-md text-contrast center-flex"
                  ></i>
                  <div class="media-body">
                    <h3 class="link-title m-0">Accordion</h3>
                    <p class="m-0 text-secondary">
                      Useful accordion elements
                    </p>
                  </div>
                </div>
              </a
              ><a
                class="dropdown-item"
                target="_blank"
                href="components/cookie-law.html"
              >
                <div class="media mb-4">
                  <i
                    class="fas fa-cookie-bite mr-2 bg-primary rounded-circle icon-md text-contrast center-flex"
                  ></i>
                  <div class="media-body">
                    <h3 class="link-title m-0">CookieLaw</h3>
                    <p class="m-0 text-secondary">
                      Comply with the hideous EU Cookie Law
                    </p>
                  </div>
                </div>
              </a>
                <h4 class="text-uppercase regular">Huge components list</h4>
                <div class="row">
                  <div class="col mr-4">
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/alert.html"
                    >Alerts</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/badge.html"
                    >Badges</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/button.html"
                    >Buttons</a
                    >
                  </div>
                  <div class="col mr-4">
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/overlay.html"
                    >Overlay</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/progress.html"
                    >Progress</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/lightbox.html"
                    >Lightbox</a
                    >
                  </div>
                  <div class="col mr-4">
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/tab.html"
                    >Tabs</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/tables.html"
                    >Tables</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="components/typography.html"
                    >Typography</a
                    >
                  </div>
                </div>
              </div>
              <div class="st-dropdown-content-group">
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="components/wizard.html"
                >Wizard
                  <span class="badge badge-pill badge-primary">New</span></a
                >
                <span class="dropdown-item d-flex align-items-center text-muted"
                >Timeline <i class="fas fa-ban ml-auto"></i
                ></span>
                <span class="dropdown-item d-flex align-items-center text-muted"
                >Process <i class="fas fa-ban ml-auto"></i
                ></span>
              </div>
            </div>
          </div>
          <div class="st-dropdown-section" data-dropdown="blog">
            <div class="st-dropdown-content">
              <div class="st-dropdown-content-group">
                <div class="row">
                  <div class="col mr-4">
                    <h4 class="regular text-uppercase">Full width</h4>
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blog/blog-post.html"
                    >Single post</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blog/blog-grid.html"
                    >Posts Grid</a
                    >
                  </div>
                  <div class="col mr-4">
                    <h4 class="regular text-uppercase">Sidebar left</h4>
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blog/blog-post-sidebar-left.html"
                    >Single post</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blog/blog-grid-sidebar-left.html"
                    >Posts Grid</a
                    >
                  </div>
                  <div class="col mr-4">
                    <h4 class="regular text-uppercase">Sidebar right</h4>
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blog/blog-post-sidebar-right.html"
                    >Single post</a
                    >
                    <a
                      class="dropdown-item"
                      target="_blank"
                      href="blog/blog-grid-sidebar-right.html"
                    >Posts Grid</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="st-dropdown-section" data-dropdown="shop">
            <div class="st-dropdown-content">
              <div class="st-dropdown-content-group">
                <a class="dropdown-item" target="_blank" href="shop/home.html">
                  <div class="media align-items-center">
                    <div
                      class="bg-success text-contrast icon-md center-flex rounded-circle mr-3"
                    >
                      <i class="fas fa-shopping-basket"></i>
                    </div>
                    <div class="media-body">
                      <h3 class="link-title m-0">Home</h3>
                      <!-- <li class="st-nav-section nav-item"><a href="#main" class="navbar-brand"><img src="logo2.svg" height='300' alt="Dashcore" class="logo logo-sticky"></a></li> -->
                      <p class="m-0 text-secondary">
                        Online store home with an outstanding UX
                      </p>
                    </div>
                  </div>
                </a
                ><a class="dropdown-item" target="_blank" href="shop/cart.html">
                <div class="media align-items-center">
                  <div
                    class="bg-info text-contrast icon-md center-flex rounded-circle mr-3"
                  >
                    <i class="fas fa-shopping-cart"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="link-title m-0">Cart</h3>
                    <p class="m-0 text-secondary">
                      Online store shopping cart
                    </p>
                  </div>
                </div>
              </a>
              </div>
              <div class="st-dropdown-content-group">
                <h3 class="link-title">
                  <i class="fas fa-money-check-alt icon"></i> Checkout
                </h3>
                <div class="ml-5">
                  <a
                    class="dropdown-item text-secondary"
                    target="_blank"
                    href="shop/checkout-customer.html"
                  >Customer <i class="fas fa-angle-right ml-2"></i> </a
                  ><a
                  class="dropdown-item text-secondary"
                  target="_blank"
                  href="shop/checkout-shipping.html"
                >Shipping Information
                  <i class="fas fa-angle-right ml-2"></i> </a
                ><a
                  class="dropdown-item text-secondary"
                  target="_blank"
                  href="shop/checkout-payment.html"
                >Payment Methods <i class="fas fa-angle-right ml-2"></i> </a
                ><a
                  class="dropdown-item text-secondary"
                  target="_blank"
                  href="shop/checkout-confirmation.html"
                >Order Review <i class="fas fa-angle-right ml-2"></i
                ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <main class="overflow-hidden">
      <!-- Payment Services Heading -->
      <header class="header payment-services-header section" id="home">
        <!-- <div class="shapes-container">
          <div class="static-shape shape-main cutout x2 bottom-right"></div>
        </div> -->
        <div class="container">
          <v-card class="text-left transparent" outlined style='min-height:600px;'>
            <slot></slot>
          </v-card>
        </div>
      </header>
      <!-- Credit Cards Management -->


      <footer class="site-footer section mt-10">
        <div class="container pb-4">
          <div class="row gap-y text-center text-md-left">
            <div class="col-md-4 mr-auto">
              <a href='https://pnk.palata-nk.ru/'>
              <img src="../assets/logo4.svg" alt="" class="logo"/>
              </a>
              <p class="mt-2 mb-md-0 text-center text-md-left">
                © 2002-2021 Все права защищены.
                При воспроизведении материалов на других сайтах обязательна
                гиперссылка на palata-nk.ru
              </p>
            </div>
            <div class="col-md-2">
              <h6 class="bold text-dark">Контакты</h6>
              <nav class="nav flex-column">
                <a class="nav-item b" href="about.html">+7 495 380-41-61 </a>
                <a class="nav-item b" href="about.html">+7 903 130-05-74</a>
              </nav>
            </div>
            <div class="col-md-2">
              <nav class="nav flex-column">
                <h6 class="bold text-dark">Офис палаты</h6>
                <a class="nav-item b" href="#"
                >г. Москва, метро Курская, Красные ворота, улица Земляной Вал,
                  дом 4, строение 1</a
                >
              </nav>
            </div>
            <div class="col-md-2">
              <nav class="nav flex-column">
                <h6 class="bold text-dark">Палата налоговых консультантов</h6>
                <a class="nav-item b" href="#">О Палате</a>
                <a class="nav-item b" href="#">Налоговое бюро</a>
                <a class="nav-item b" href="#">Членство</a>
                <!-- <a class="nav-item b " href="#">Реестры</a>
                        <a class="nav-item b " href="#">Международная деятельность</a>
                        <a class="nav-item b " href="#">Проекты</a>
                        <a class="nav-item b " href="#">Коворкинг</a>
                        <a class="nav-item b " href="#">Мероприятия</a> -->
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </main>
    <!-- themeforest:js -->
  </div>
</template>


<style scoped>
.mb-05 {
  margin-bottom: 2px;
}

a {
  color: white !important;
  cursor: pointer !important;
}

.b {
  color: #333 !important;
}

.gg-arrow-long-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 50%;
  top: 40%;
  left: 40%;
  right: 10%;
  height: 6px;
}

.gg-arrow-long-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}

@import "https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700,900";
@import "https://fonts.googleapis.com/css?family=Caveat";

@import "../pages/landing/landing_css/theme.css";

@import "../pages/landing/landing_css/all.css";
/* @import "./landing_css/aos.css"; */
@import "../pages/landing/landing_css/cookieconsent.min.css";
@import "../pages/landing/landing_css/magnific-popup.css";
@import "../pages/landing/landing_css/odometer-theme-minimal.css";
@import "../pages/landing/landing_css/prism-okaidia.css";
@import "../pages/landing/landing_css/simplebar.css";
@import "../pages/landing/landing_css/smart_wizard.css";
@import "../pages/landing/landing_css/smart_wizard_theme_arrows.css";
@import "../pages/landing/landing_css/smart_wizard_theme_circles.css";
@import "../pages/landing/landing_css/smart_wizard_theme_dots.css";
@import "../pages/landing/landing_css/swiper.css";
@import "../pages/landing/landing_css/theme.css";
@import "../pages/landing/landing_css/rtl.css";
@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>


<script>
import AOS from 'aos'
import LogoSmall from '@/components/LogoSmall'
export default {
  components: { LogoSmall },
  data() {
    return {
      quest_org: [
        {
          text: "Текст ответа на вопрос",
          title: "Что такое аккредитация образовательной организции?",
        },
        {text: "Текст ответа на вопрос", title: "Как пройти аккредитацию?"},
        {
          text: "Текст ответа на вопрос",
          title: "Свидетельство об аккредитации",
        },
        {text: "Текст ответа на вопрос", title: "Какие нужны документы? "},
      ],
      quest_attested: [
        {
          text:
            "Повышение квалификации можно пройти в Палате налоговых консультантов или в одной из аккредитованных ПНК образовательных организаций. Формы повышения квалификации: Практикумы; Консультационные семинары; Налоговые видео-семинары; Зачет ситуаций; Психологические тренинги; Участие в проекте «Горячая линия»; Непрерывный курс в образовательной организации",
          title:
            "Заканчивается срок действия квалификационного аттестата, в какой форме можно пройти повышение квалификации?",
        },
        {
          text:
            "В течение 2 лет действия квалификационного аттестата налоговый консультант проходит повышение квалификации в объеме 72 ак. час.",
          title:
            "Сколько часов нужно для продления срока действия квалификационного аттестата?",
        },
        {
          text:
            "1.	Заявление поданное через личный кабинет <br>2.	Согласие на обработку персональных данных. <br>3.	Сканы удостоверения(й) подтверждающие 72ч. повышения квалификации. <br>4.	Подтверждение оплаты членских взносов за последние 3 года, включая текущий год",
          title:
            "Какие документы, необходимые для оформления свидетельства о продлении срока действия квалификационного аттестата ",
        },
        {
          text:
            "Да, можно. Если срок действия Вашего квалификационного аттестата истек более года назад, Вам необходимо: <br>1.	подать ходатайство о восстановлении квалификационного аттестата; <br>2.	к ходатайству приложить подтверждение оплаты задолженности по членским взносам за 3 календарных года, включая текущий; <br>3.	пройти повышение квалификации в объеме 72 ак. часов ",
          title:
            "Можно ли восстановить срок действия квалификационного аттестата?",
        },
        {
          text:
            "Закончился срок действия квалификационного аттестата или не оплачен членский взнос",
          title:
            "Не вижу себя в реестре аттестованных налоговых консультантов?",
        },
      ],
      quest_cons: [
        {
          text:
            "В зависимости от выбранной программы, необходимым условием допуска является наличие среднего профессионального (экономического/юридического) или высшего",
          title: "Требуется ли высшее образование для обучения?",
        },
        {
          text:
            "180, 260  или 260 аккадемических часов требуется для того, чтобы освоить программу",
          title: "Какое количество академических часов длится обучение?",
        },
        //   {text: 'Налоговый консультант - специалист оказывающий квалифицированное содействие налогоплательщикам (физическим и юридическим лицам) при исполнении налоговых обязанностей.  Для этого налоговый консультант должен обладать знаниями экономики, юриспруденции и собственно налогообложения. Требования к квалификации содержаться в Квалификационном справочнике. В настоящее время Союзом «ПНК» разработан и в декабре 2020 года внесён в Минтруд РФ проект профстандарта «Консультант по налогам и сборам». Деятельность налогового консультанта (консультанта по налогам и сборам) существенно отличается от деятельности представителей других профессий. Это легко увидеть ознакомившись с трудовыми функциями и действиями.' ,title: 'Кто такой налоговый консультант? Чем он отличается от юриста, бухгалтера, аудитора и т.п.?'},
        {
          text:
            "Обучение по программе Палаты налоговых консультантов проводят аккредитованные  образовательные организации.Обучение завершается обязательной сдачей итогового контроля знаний (экзамена). ",
          title: "Как проходит обучение?",
        },
      ],
    };
  },
  methods: {
    page(page) {
      location.href = page;
    },
    logout() {
      localStorage.removeItem('uid')
      localStorage.removeItem('token')
      this.page('/')
    }
  },
  created() {
    AOS.init()
  }
}
</script>
