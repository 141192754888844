<template>
  <v-app v-if="isAppReady">
    <div class='gradient-up-line' style="text-align: center;heigh:10px; width:100%; color:white;"> 
      <strong>Уважаемые пользователи! Портал находится на стадии тестирования.</strong>
      </div>
    <!-- Layout component -->
    <component :is="currentLayout">
      <router-view />
    </component>

    <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" bottom>
      {{ toast.message }}
      <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { auth } from './firebase'

import simpleLayout from './layouts/simple'
import initialLayout from './layouts/initial'
import defaultLayout from './layouts/default'
import errorLayout from './layouts/error'
import OrgLayout from './layouts/org_layout'
import NewLayout from './layouts/newlay'
export default {
  components: {
    initialLayout,
    defaultLayout,
    errorLayout,
    simpleLayout,
    OrgLayout,
    NewLayout
  },
  computed: {
    ...mapState('app', ['toast', 'isAppReady']),
    currentLayout: function() {
      const layout = this.$route.meta.layout || 'default'

      return layout + 'Layout'
    }
  }
}
</script>

<style lang="scss">
@import "./assets/styles/theme.scss";
.gradient-up-line{
    background: #43c6ac; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #43c6ac, #191654); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #43c6ac, #191654);
}
</style>