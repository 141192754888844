import Vue from 'vue'

import Router from 'vue-router'
import { auth } from '../firebase'
import VueScrollTo from 'vue-scrollto'
// import VueJivosite from '@bchteam/vue-jivosite'
import store from '../store'
Vue.use(Router)
Vue.use(VueScrollTo)
// Vue.use(VueJivosite, { widgetId: 'MTm3wYnwst' })
export const routes = [{
  path: '/',
  name: 'landing',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/landing/landing2.vue'),
  meta: {
    layout: 'landing'
  }
},{
  path: '/restore',
  name: 'restore',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/initial/restore.vue'),
  meta: {
    layout: 'initial',
    redirectIfAuth: true
  }
},{
  path: '/reestr-company',
  name: 'reestr_company',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/reestr/companies.vue'),
  meta: {
    layout: 'simple',
    redirectIfAuth: false
  }
},{
  path: '/reestr-programs',
  name: 'reestr_programs',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/reestr/programs.vue'),
  meta: {
    layout: 'simple',
    redirectIfAuth: false
  }
},{
  path: '/reestr-consultants',
  name: 'reestr_consultants',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/reestr/consultants.vue'),
  meta: {
    layout: 'simple',
    redirectIfAuth: false
  }
},{
  path: '/reestr-education',
  name: 'reestr_education',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/reestr/educations.vue'),
  meta: {
    layout: 'simple',
    redirectIfAuth: false
  }
},{
  path: '/reestr-groups',
  name: 'reestr_groups',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/reestr/groups.vue'),
  meta: {
    layout: 'simple',
    redirectIfAuth: false
  }
},{
  path: '/company-info',
  name: 'company_info',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/reestr/company_info.vue'),
  meta: {
    layout: 'simple',
    redirectIfAuth: false
  }
},{
  path: '/education-info',
  name: 'education_info',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/reestr/education_info.vue'),
  meta: {
    layout: 'simple',
    redirectIfAuth: false
  }
},{
  path: '/lectors',
  name: 'lectors',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/landing/lectors.vue'),
  meta: {
    layout: 'landing',
  }
},{
  path: '/login',
  name: 'login',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/initial/login.vue'),
  meta: {
    layout: 'initial',
    redirectIfAuth: true
  }
},{
  path: '/login-org',
  name: 'loginorg',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/initial/loginorg.vue'),
  meta: {
    layout: 'initial',
    redirectIfAuth: true
  }
},{
  path: '/register',
  name: 'register',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/consultant/register.vue'),
  meta: {
    layout: 'initial',
    redirectIfAuth: true
  },
},{
  path: '/confirm_email',
  name: 'confirm_email',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/consultant/confirm_email.vue'),
  meta: {
    layout: 'initial',
    redirectIfAuth: true
  },
},{
  path: '/personal',
  name: 'personal',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/personal.vue'),
  meta: {
    requiredAuth: true
  }
},{
  path: '/payments',
  name: 'payments',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/payments.vue'),
  meta: {
    requiredAuth: true
  }
},{
  path: '/consultant',
  name: 'consultant',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/board.vue'),
  meta: {
    //requiresAuth: true,
    redirectIfNotFilled: true
  }
},{
  path: '/consultant-profile',
  name: 'consultant_profile',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/consultant_profile.vue'),
  meta: {
    requiredAuth: true,
    layout: 'org'
  }
},{
  path: '/org',
  name: 'org',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/board.vue'),
  meta: {
    requiredAuth: true,
    layout: 'org'
  }
},{
  path: '/org-docs',
  name: 'org_docs',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/docs.vue'),
  meta: {
    requiredAuth: true,
    layout: 'org'
  }
},{
  path: '/orgsettings',
  name: 'orgsettings',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/orgsettings.vue'),
  meta: {
    requiredAuth: true,
    layout: 'org'
  }
},{
  path: '/listgroup',
  name: 'listgroup',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/listgroup.vue'),
  meta: {
    requiredAuth: true,
    layout: 'org'
  }
},{
  path: '/groupconsultants',
  name: 'groupconsultants',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/groupconsultants.vue'),
  meta: {
    requiredAuth: true,
    layout: 'org'
  }
},{
  path: '/listrequests',
  name: 'listrequests',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/listrequests.vue'),
  meta: {
    requiredAuth: true,
    layout: 'org'
  }
},{
  path: '/events',
  name: 'events',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/events.vue'),
  meta: {
    requiresAuth: false,
    layout: 'new'
  }
},{
  path: '/event',
  name: 'event',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/event.vue'),
  meta: {
    requiresAuth: false,
  }
},{
  path: '/mybook',
  name: 'mybook',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/mybook.vue'),
  meta: {
    requiresAuth: false
  }
},
{
  path: '/docs',
  name: 'docs',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/mydoc.vue'),
  meta: {
    requiredAuth: true
  }
},{
  path: '/request-first',
  name: 'request_first',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/request_first.vue'),
  meta: {
    requiresAuth: false,
    layout: 'simple'
  }
},{
  path: '/request-second',
  name: 'request_second',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/request_second.vue'),
  meta: {
    requiresAuth: false,
    layout: 'simple'
  }
},{
  path: '/request-second-video',
  name: 'request_second_video',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/request_second_video.vue'),
  meta: {
    requiresAuth: true,
    layout: 'simple'
  }
},{
  path: '/request-org',
  name: 'request_org',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/consultant/request_org.vue'),
  meta: {
    requiresAuth: false,
    layout: 'simple'
  }
},{
  path: '/library',
  name: 'library',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/library/library.vue'),
  meta: {
    requiresAuth: false,
    layout: 'new'
  }
},{
  path: '/video-seminars',
  name: 'video_seminars',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/library/video_seminars.vue'),
  meta: {
    requiresAuth: false,
    layout: 'new'
  }
},{
  path: '/video-seminar',
  name: 'video_seminar',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/library/video_seminar.vue'),
  meta: {
    requiresAuth: false,
    layout: 'new'
  }
}, {
  path: '/archive',
  name: 'archive',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/archive.vue'),
  meta: {
    requiresAuth: false,
    layout: 'org'
  }
}, {
  path: '*',
  name: 'error',
  component: () => import(/* webpackChunkName: "error" */ '@/pages/error/index.vue'),
  meta: {
    layout: 'error'
  }
}, {
  path: '/polls/emails',
  name: 'polls_emails',
  component: () => import(/* webpackChunkName: "error" */ '@/pages/polls/polls_emails.vue'),
  meta: {
    layout: 'simple'
  }
},{
  path: '/not_mailing',
  name: 'not_mailing',
  component: () => import(/* webpackChunkName: "error" */ '@/pages/consultant/not_mailing.vue'),
  meta: {
    layout: 'simple'
  }
}, {
  path: '/payments-org',
  name: 'payments_org',
  component: () => import(/* webpackChunkName: "board" */ '@/pages/org/payments_org.vue'),
  meta: {
    requiresAuth: false,
    layout: 'org'
  }
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})
/**
 * Проверяет localStorage на наличие токена пользователя
 * @returns {boolean}
 */
const isAuth = () => {
  return !!localStorage.token
}
/**
 * Запись о том, что isPersonalFilled в localStorage делаются в GET_INFO_PROFILE
 * Не забыть об этом!!!
 * @returns {string|boolean}
 */
const checkPersonalFilled = () => {
  if (localStorage.isPersonalFilled) {
    return localStorage.getItem('isPersonalFilled')
  } else {
    return true
  }
}

router.beforeEach((to, from, next) => {
  const currentUser = isAuth()
  const isPersonalFilled = checkPersonalFilled()

  if (currentUser && to.name === 'consultant' && (to.matched.some((record) => record.meta.redirectIfNotFilled))) {
    if (isPersonalFilled === 'false') {
      return next({
        name: 'personal'
      })
    }
  }
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (currentUser) {
      return next()
    } else {
      return next({
        name: 'login'
      })
    }
  } else {
    if (currentUser && (to.matched.some((record) => record.meta.redirectIfAuth))) {
      return next({
        name: 'consultant'
      })
    }
  }

  return next()
})

export default router
