export function base_url() {
  let base_url = window.location.origin

  if (base_url === 'http://localhost:8082' || base_url === 'http://localhost:8083') {
    base_url = 'http://127.0.0.1:8000/'
  } else if (base_url.includes('localhost')) {
    base_url = 'http://localhost:8000/'
  } else if (base_url.includes('104.248.26.120')) {
    base_url = 'http://localhost:8000/'
  } else {
    base_url = 'https://edu.palata-nk.ru/'
  }

  return base_url
}
