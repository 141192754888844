<template>
  <div class="text-center">
    <a href='https://pnk.palata-nk.ru/'>
      <figure class="mockup mr-1">
        <img
          style="height: 60px"
          src="../assets/logo4.svg"
          alt="..."
        />
      </figure>
    </a>
  </div>
</template>

<script>
import { base_url } from '@/services/base_url'

export default {
  name: 'LogoSmall',
  data() {
    return {
      url: base_url
    }
  }
}
</script>

<style scoped>

</style>
