import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  toast: {
    show: false,
    color: 'black',
    message: '',
    timeout: 3000
  },

  // User
  user: null,
  lectors: [],
  user_files: {},
  profile: null,
  isAppReady: false,

  //utils
  educations_list: [
    'Высшее экономическое',
    'Высшее юридическое',
    'Высшее непрофильное',
    'Среднее профессиональное экономическое',
    'Среднее профессиональное юридичекое',
    'Другое'
  ],

  // App
  settings: {
    maintenance: false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
