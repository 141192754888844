import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=4f06141a&scoped=true&"
import script from "./simple.vue?vue&type=script&lang=js&"
export * from "./simple.vue?vue&type=script&lang=js&"
import style0 from "./simple.vue?vue&type=style&index=0&id=4f06141a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f06141a",
  null
  
)

export default component.exports