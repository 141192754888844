import axios from 'axios'
import { base_url } from '@/services/base_url'

const API = axios.create()

API.interceptors.request.use((config) => {
  config.params = config.params || {}
  if (localStorage.token) {
    config.headers.Authorization = `token ${localStorage.token}`
  }

  return config
})

function get_url(slug) {
  const baseurl = base_url()
  const urls = {
    'regions': 'api/references/regions',
    'lectors': 'api/references/lectors',
    'org_by_region': 'api/partners/org-by-region',
    'get_programs': 'api/references/programs',
    'signup_consultant':'api/consultants/signup',
    'find_consultant':'api/consultants/find-consultant',
    'download_zayavleniye':'api/consultants/download-zayavleniye',
    'download_schet_povyshenie':'api/consultants/download-schet-povyshenie',
    'profile_info':'api/consultants/profile-info',
    'get_user_files': 'api/consultants/get-user-files',
    'restore_password': 'api/consultants/restore_password',
    'change_password': 'api/consultants/change_password',
    'sign_in': 'api/consultants/sign_in',
    'finish_upload':'api/consultants/finish_upload',
    'get_library': 'api/lib/get_library',
    'get_material': 'api/lib/get_material',
    'update_individual': 'api/consultants/update_individual',
    'register':'api/consultants/register',
    'confirm_email':'api/consultants/confirm_email',
    'get_companies':'api/references/companies',
    'get_company_info':'api/references/company_info',
    'get_consultants':'api/consultants/get-consultants',
    'get_educations':'api/partners/get-organisations',
    'update_education':'api/partners/update-education',
    'get_education_data':'api/partners/get-education-data',
    'get_education_groups':'api/partners/get-education-groups',
    'create_group':'api/partners/create-group',
    'get_group_consultants':'api/partners/get-group-consultants',
    'get_all_consultants':'api/consultants/get-list-consultants',
    'add_consultant_to_group':'api/partners/add-consultant-to-group',
    'get_all_programs':'api/references/all-programs',
    'get_seminars':'api/lib/get-video-seminars',
    'register_to_seminar':'api/lib/register-to-seminar',
    'get_seminar_data':'api/lib/get-seminar-data',
    'upload_photo_consultant':'api/consultants/upload-photo',
    'get_org_groups':'api/partners/get-org-groups',
    'create_edu_proposal': 'api/consultants/create-edu-proposal',
    'create_edu_proposal_video': 'api/consultants/create-edu-proposal-video',
    'get_statuses':'api/partners/get-statuses',
    'update_group':'api/partners/update-group',
    'get_requests':'api/partners/get-requests',
    'get_events':'api/events/get_events',
    'register_to_event':'api/events/register_to_event',
    'get_events_auth':'api/events/get_events_auth',
    'register_to_event_auth':'api/events/register_to_event_auth',
    'get_event':'api/events/get_event',
    'get_event_auth':'api/events/get_event_auth',
    'get_payments_history':'api/consultants/get-payments-history',
    'get_groups_consultants':'api/partners/get-groups-consultants',
    'get_consultant_info':'api/partners/get-consultant-info',
    'move_consultant':'api/partners/move-consultant',
    'get_all_groups':'api/partners/get-all-groups',
    'get_education_info':'api/partners/get-education-info',
    'get_groups':'api/partners/get-groups',
    'add_consultants_excel':'api/partners/add-consultants-excel',
    'new_trajectory':'api/consultants/new-trajectory',
    'get_adverts':'api/partners/get-adverts',
    'get_org_files':'api/partners/get-org-files',
    'get_proposals_from_user': 'api/consultants/get-proposals-from-user',
    'register_order': 'api/consultants/register-order',
    'check_order':'api/consultants/check-order',
    'check_attestat_exist': 'api/consultants/check-attestat-exist',
    'get_consultants_from_room': 'api/partners/get-consultants-from-room',
    'get_organisation_info': 'api/partners/get-organisation-info',
    'get_group_by_id': 'api/partners/get-group-by-id',
    'get_price_for_chlen': 'api/consultants/get-price-for-chlen',
    'check_payment_from_consultant': 'api/consultants/check-payment-from-consultant',
    'get_consultants_from_archive': 'api/partners/get-consultants-from-archive-2',
    'get_active_polls': 'api/polls/get-active',
    'create_result': 'api/polls/create-result',
    'get_groups_others_pnk': 'api/partners/get-groups-others-pnk',
    'get_consultant_by_hash_and_get_polls': 'api/consultants/get-consultant-by-hash-and-get-polls',
    'get_consultant_by_hash_and_not_mailing': 'api/consultants/get-consultant-by-hash-and-not-mailing',
    'make_ticket_pay': 'api/consultants/make-ticket-pay',
    'get_groups_and_programs': 'api/partners/get-groups-and-programs',
    'get_releases_consultant': 'api/consultants/get-releases-consultant',
    'make_payment_invoice_for_org': 'api/consultants/make-payment-invoice-for-org',
    'make_payment_invoice_org': 'api/partners/make-payment-invoice-org'
  }

  return baseurl + urls[slug]
}

const SIGN_IN = async (ctx, data) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const url = get_url('sign_in')
    const res = await API.get(url + '?email=' + data.email + '&password=' + data.password+ '&org=' + data.org)

    localStorage.setItem('uid', res.data.id)

    if (res.data.user.type) {
      localStorage.setItem('userType', res.data.user.type)
    }
    if (res.data.token) {
      localStorage.setItem('token', res.data.token)
    }
    if (res.data.user.is_personal_filled !== undefined) {
      localStorage.setItem('isPersonalFilled', res.data.user.is_personal_filled)
    }

    return res.data
  } catch (e) {
    // console.log(e.response)
    throw e
  }
}

const CREATE_TRAJECTORY = async (ctx,data) => {
  try {
    const url = get_url('new_trajectory')
    const res = await API.post(url, data)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const REGISTER = async (ctx,data) => {
  try {
    const url = get_url('register')
    const res = await API.get(url + '?email=' + data.email + '&password=' + data.password + '&inn=' + data.inn)

    localStorage.setItem('uid', res.data.id)
    if (res.data.token) {
      localStorage.setItem('token', res.data.token)
    }

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_ADVERTS = async (ctx) => {
  try {
    const url = get_url('get_adverts')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Возвращает список платежей консультанта
 * без платежей со статусом "Удален"
 * @param ctx - не используется
 * @returns {Promise<any>}
 */
const GET_PAYMENTS_HISTORY = async (ctx) => {
  try {
    const url = get_url('get_payments_history')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const REGISTER_ORDER = async (ctx, data) => {
  try {
    const url = get_url('register_order')
    const res = await API.get(url + '?type=' + data.type + '&amount=' + data.amount)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const CHECK_ORDER = async (ctx, order_id) => {
  try {
    const url = get_url('check_order')
    const res = await API.get(url + '?order_id=' + order_id)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_GROUP_CONSULTANTS = async (ctx,data) => {
  try {
    const url = get_url('get_group_consultants')
    const res = await API.get(url + '?id=' + data.id)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_STATUSES = async (ctx) => {
  try {
    const url = get_url('get_statuses')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_ORG_GROUPS = async (ctx, data) => {
  try {
    const url = get_url('get_org_groups')
    const res = await API.get(url, { params:
        { id: data.id,
          level: data.level,
          short: data.short || 0 } })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_EVENTS = async (ctx) => {
  try {
    const url = get_url('get_events')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_EVENT = async (ctx, data) => {
  try {
    const url = get_url('get_event')

    if (data.id) {
      const res = await API.get(url + '?id=' + data.id)

      return res.data
    } else {
      const res = await API.get(url + '?query=' + data.query)

      return res.data
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_EVENT_AUTH = async (ctx, data) => {
  try {
    const url = get_url('get_event_auth')

    if (data.id) {
      const res = await API.get(url + '?id=' + data.id)

      return res.data
    } else {
      const res = await API.get(url + '?query=' + data.query)

      return res.data
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_EVENTS_AUTH = async (ctx) => {
  try {
    const url = get_url('get_events_auth')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_ALL_CONSULTANTS = async (ctx,data) => {
  try {
    const url = get_url('get_all_consultants')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_SEMINARS = async (ctx,data) => {
  try {
    const url = get_url('get_seminars')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_SEMINAR_DATA = async (ctx,data) => {
  try {
    const url = get_url('get_seminar_data')
    const res = await API.get(url + '?id=' + data.id)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const REGISTER_TO_SEMINAR = async (ctx,id) => {
  try {
    const url = get_url('register_to_seminar')
    const res = await API.post(url, { data:{ id:id } })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const ADD_CONSULTANTS_EXCEL = async (ctx,data) => {
  try {
    const url = get_url('add_consultants_excel')
    const res = await API.post(url, data)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_ALL_PROGRAMS = async (ctx,data) => {
  try {
    const url = get_url('get_all_programs')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const CONFIRM_EMAIL = async (ctx,data) => {
  try {
    const url = get_url('confirm_email')
    const res = await API.get(url + '?code=' + data.code)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const CHANGE_PASSWORD = async (ctx,email) => {
  try {
    const url = get_url('restore_password')
    const res = await API.get(url + '?email=' + email)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
const UPDATE_PASSWORD = async (ctx,data) => {
  try {
    const url = get_url('change_password')
    const res = await API.get(url + '?email=' + data.email + '&password=' + data.password)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const UPDATE_INDIVIDUAL = async (ctx,data) => {
  try {
    const url = get_url('update_individual')

    return await API.post(url, data)
  } catch (e) {
    console.log(e)
    throw e
  }
}

const UPDATE_EDUCATION = async (ctx,data) => {
  try {
    const url = get_url('update_education')
    const res = await API.post(url, data)

    return res
  } catch (e) {
    console.log(e)
    throw e
  }
}

const SIGN_UP_TO_EVENT = async (ctx,data) => {
  try {
    const url = get_url('register_to_event')
    const res = await API.post(url, data)

    return res
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Регистрация на мероприятия от зарегистрированных консультантов
 * @param ctx - не используется
 * @param data - { event_id: int }
 * @returns {Promise<any>}
 */
const REGISTER_TO_EVENT = async (ctx, data) => {
  try {
    const url = get_url('register_to_event_auth')

    return  (await API.post(url, data)).data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const CREATE_GROUP = async (ctx, data) => {
  try {
    const url = get_url('create_group')

    return await API.post(url, data)
  } catch (e) {
    console.log(e)
    throw e
  }
}

const ADD_CONSULTANT_TO_GROUP = async (ctx,data) => {
  try {
    const url = get_url('add_consultant_to_group')
    const res = await API.post(url, data)

    return res
  } catch (e) {
    console.log(e)
    throw e
  }
}

const INN_INFO = async (ctx,data) => {

  try {
    const url = 'https://kadder.kazna.tech/api/v3/company-info/'
    const res = await axios.get(url + data.inn)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const FIND_CONSULTANT = async (ctx,data) => {

  try {
    const url = get_url('find_consultant')
    const res = await API.get(url + '?number=' + data.number)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 *
 * @param ctx - коммитит в state файлы
 * @param uid: int - id пользователя
 * @returns {Promise<void>}
 * @constructor
 */
const USER_FILES = async (ctx, uid) => {
  try {
    const url = get_url('get_user_files')
    const res = await API.get(url + '?uid=' + uid)

    ctx.commit('UPDATE_USER_FILES', res.data.files)
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Получает список документов организации
 * @param ctx - сохраняет документы организации в store
 * @param uid - номер организации
 * @returns {Promise<void>} - ничего не возвращает
 * @constructor
 */
const ORG_FILES = async (ctx, uid) => {
  try {
    const url = get_url('get_org_files')
    const res = await API.get(url + '?uid=' + uid)

    ctx.commit('UPDATE_USER_FILES', res.data.files)

    return res.data.files
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Заявка на обучение от анонимов и зарегистрированных пользователей
 * ((((и на повышение квалификации от консультанта, если у него не записан аттестат)))) уже нет
 * @param ctx - контекст не используется
 * @param data - в POST(
 * email: str, phone: str,
 * educated: bool, region: Optinal[int], org: int, program: int,
 * online: bool, group: Optinal[int], attestat, education: str,
 * name: str, middle_name: str, last_name: str, address: str)
 * @returns {Promise<any>}
 * @constructor
 */
const SIGN_UP_CONSULTANT = async (ctx, data) => {
  try {
    const url = get_url('signup_consultant')
    const res = await API.post(url,data)

    if (res.status === 206) {
      // когда есть совпадение email или телефона от анонимного пользователя
      return { status: 206, message: res.data.message }
    }

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const CREATE_EDU_PROPOSAL = async (ctx,data) => {

  try {
    const url = get_url('create_edu_proposal')
    const res = await API.post(url,data)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Создает заявление консультанта на видеосеминары
 * @param ctx - не используется
 * @param group_id: int - id группы повышения квалификации
 * @returns {Promise<any>}
 * @constructor
 */
const CREATE_EDU_PROPOSAL_VIDEO = async (ctx, group_id = 0) => {
  try {
    const url = get_url('create_edu_proposal_video')
    const res = await API.post(url, { group_id: group_id })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const LOAD_PHOTO = async (ctx,file) => {

  try {
    const url = get_url('upload_photo_consultant')
    const res = await API.post(url, file)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const UPDATE_GROUP = async (ctx,data) => {

  try {
    const url = get_url('update_group')

    console.log(data)
    const res = await API.post(url, data)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_REQUESTS = async (ctx,data) => {

  try {
    const url = get_url('get_requests') + '?q=' + data.q + '&page=' + data.page

    console.log(data)
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_GROUPS_CONSULTANTS = async (ctx,data) => {

  try {
    const url = get_url('get_groups_consultants') + '?q=' + data.q + '&page=' + data.page

    console.log(data)
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

/**
 * Запрашивает данные консультанта и записывает их в state
 * @param ctx - делает коммит UPDATE_PROFILE - данные пользователя
 * @returns {Promise<*>}
 * @constructor
 */
const GET_INFO_PROFILE = async (ctx) => {
  try {
    const url = get_url('profile_info')
    const id = localStorage.uid

    if (!id) {
      return
    }
    // запрос профиля консультанта
    const res = await API.get(url + '?id=' + id)

    if (res.data.data.user.is_personal_filled !== undefined) {
      localStorage.setItem('isPersonalFilled', res.data.data.user.is_personal_filled)
    }

    // изменяем данные профиля консультанта в state
    ctx.commit('UPDATE_PROFILE', res.data.data)

    // eslint-disable-next-line consistent-return
    return res.data.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

// Для организаций
const GET_CONSULTANT_PROFILE_INFO = async (ctx, id) => {

  try {
    const url = get_url('get_consultant_info')

    console.log(id)
    const res = await API.get(url + '?id=' + id)

    return res.data.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 *
 * @param ctx - не используется
 * @param data:
 * consultant_id: int
 * group_id: str
 * @returns {Promise<*>}
 * @constructor
 */
const MOVE_CONSULTANT = async (ctx, data) => {
  try {
    const url = get_url('move_consultant')
    const res = await API.get(url,
      { params: { consultant_id: data.consultant_id, group_id: data.group_id } })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

//Перенос консультанта в другую группу
const MOVE_CONSULTANT_TO_GROUP = async (ctx, data) => {

  try {
    const url = get_url('move_consultant')
    const res = await API.post(url, data)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_INFO_EDUCATION = async (ctx) => {

  try {
    const url = get_url('get_education_data')

    const id = localStorage.uid

    if (!id) {
      location.href = '/login/?auth_experied=true'

      return
    }
    const res = await API.get(url)

    ctx.commit('UPDATE_PROFILE', res.data.data)
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_ZAYAVLEMIYE = async (ctx,data) => {

  try {
    const url = get_url('download_zayavleniye')
    const res = await API.get(url)

    window.open(base_url() + res.data.url,'_blank')

    return
  } catch (e) {
    console.log(e)
    throw e
  }
}

const FINISH_UPLOAD = async (ctx,data) => {

  try {
    const url = get_url('finish_upload')
    const res = await API.get(url + '?type=' + data.type + '&id=' + data.id)

    window.open(base_url() + res.data.url,'_blank')

    return
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_SCHET_POVYSHENIE = async (ctx,data) => {

  try {
    const url = get_url('download_schet_povyshenie')
    const res = await API.get(url + '?type=' + data.type + '&id=' + data.id)

    window.open(base_url() + res.data.url,'_blank')

    return
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_PROGRAMS = async (ctx,data) => {

  try {
    const url = get_url('get_programs')
    let { level } = data

    if (!level) {
      level = 'all'
    }
    const res = await API.get(url + '?id=' + data.id + '&online=' + data.online + '&level=' + level)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_GROUPS = async (ctx) => {

  try {
    const url = get_url('get_groups')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_EDUCATION_GROUPS = async (ctx,data) => {
  try {
    console.log(data.q, data.page)
    const url = get_url('get_education_groups') + '?q=' + data.q + '&page=' + data.page
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_ALL_GROUPS = async (ctx,data) => {
  try {
    console.log(data.q, data.page)
    const url = get_url('get_all_groups') + '?q=' + data.q + '&page=' + data.page + '&type=' + data.type
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

/**
 * Возвращает список организаций в указанном регионе
 * @param ctx - контекст не используется
 * @param data: dict
 * @returns {Promise<any>}
 */
const GET_ORGS = async (ctx,data) => {
  try {
    const url = get_url('org_by_region')
    const res = await API.get(url + '?id=' + data.id)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_LECTORS = async (ctx) => {
  try {
    const url = get_url('lectors')
    const res = await API.get(url)

    ctx.commit('UPDATE_LECTORS', res.data)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_REGIONS = async (ctx) => {

  try {
    const url = get_url('regions')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_COMPANIES = async (ctx) => {

  try {
    const url = get_url('get_companies')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_EDUCATIONS = async (ctx) => {

  try {
    const url = get_url('get_educations')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_EDUCATION_INFO = async (ctx, id) => {

  try {
    const url = get_url('get_education_info') + '?id=' + id
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_COMPANY_INFO = async (ctx, id) => {

  try {
    const url = get_url('get_company_info') + '?id=' + id
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_CONSULTANTS = async (ctx, data) => {

  try {
    console.log(data.q, data.page)
    const url = get_url('get_consultants') + '?q=' + data.q + '&page=' + data.page
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_LIBRARY = async (ctx, priv) => {

  try {
    const url = get_url('get_library') + '?private=' + priv
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_MATERAIL = async (ctx, id) => {

  try {
    const url = 'https://edu.palata-nk.ru/api/lib/get_material'

    return url + '?id=' + id
  } catch (e) {
    console.log(e)
    throw e
  }
}

const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit('HIDE_TOAST')

  setTimeout(() => {
    commit('SHOW_TOAST', {
      color: 'black',
      message,
      timeout: 3000
    })
  })
}

const showError = ({ state, commit }, { message = 'Failed!', error }) => {
  if (state.toast.show) commit('HIDE_TOAST')

  setTimeout(() => {
    commit('SHOW_TOAST', {
      color: 'error',
      message: message + ' ' + error.message,
      timeout: 10000
    })
  })
}

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit('HIDE_TOAST')

  setTimeout(() => {
    commit('SHOW_TOAST', {
      color: 'success',
      message,
      timeout: 3000
    })
  })
}
/**
 * Получает данные о последнем заявлении пользователя
 * @param ctx - не используется
 * @returns {Promise<any>}
 */
const GET_PROPOSALS_FROM_USER = async (ctx) => {
  try {
    const url = get_url('get_proposals_from_user')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 *
 * @param ctx - не используется
 * @param attestat: str - номер аттестата
 * @returns {Promise<boolean>}
 * @constructor
 */
const CHECK_ATTESTAT_EXIST = async (ctx, attestat) => {
  try {
    const url = get_url('check_attestat_exist')
    const res = await API.get(url, { params: { attestat: attestat } })

    return res.data
  } catch (e) {
    throw e
  }
}
/**
 * Cписок консультантов из комнаты ожидания для конкретной организации
 * { items: [], total: int }
 * @param ctx не используется
 * @param group_id:number - id группы, чтобы понять на бэке "повышение" или "обучение"
 * @returns {Promise<any>}
 * @constructor
 */
const GET_CONSULTANTS_FROM_ROOM = async (ctx, group_id = 0) => {
  try {
    const url = get_url('get_consultants_from_room')
    const res = await API.get(url, { params: { group_id: group_id } })

    return res.data
  } catch (e) {
    throw e
  }
}

const GET_ORGANISATION_INFO = async (ctx) => {
  try {
    const url = get_url('get_organisation_info')
    const res = await API.get(url)

    return res.data.data
  } catch (e) {
    throw e
  }
}
/**
 * Возвращает описание группы по его id {data: ...}
 * @param ctx - не используется
 * @param group_id: int - id группы, по которому будет поиск
 * @returns {Promise<*>}
 */
const GET_GROUP_BY_ID = async (ctx, group_id) => {
  try {
    const url = get_url('get_group_by_id')
    const res = await API.get(url, { params: { group_id: group_id } })

    return res.data.data
  } catch (e) {
    throw e
  }
}
/**
 * Получает цены для членского взноса
 * @param ctx - не испотльзуется
 * @returns {Promise<any>}
 * @constructor
 */
const GET_PRICE_FOR_CHLEN = async (ctx) => {
  try {
    const url = get_url('get_price_for_chlen')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    throw e
  }
}

const CHECK_PAYMENT_FROM_CONSULTANT = async (ctx, data) => {
  try {
    const url = get_url('check_payment_from_consultant')
    const res = await API.get(url, { params: data })

    return res.data
  } catch (e) {
    throw e
  }
}
/**
 * Список консультантов для организации, которые выпустились ранее
 * @param ctx - не используется
 * @param data
 * @returns {Promise<any>}
 * @constructor
 */
const GET_CONSULTANTS_FROM_ARCHIVE = async (ctx, data) => {
  try {
    const url = get_url('get_consultants_from_archive')
    const res = await API.get(url,
      { params: {
        page_size: data.page_size,
        page: data.page,
        date_lte: data.date_end,
        date_gte: data.date_start,
        search: data.search,
        group: data.group,
        group__program: data.program
      } })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Получает список активных голосований
 * @param ctx
 * @returns {Promise<any>}
 * @constructor
 */
const GET_ACTIVE_POLLS = async (ctx) => {
  try {
    const url = get_url('get_active_polls')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Результата голоса консультанта за конкретное голосование
 * @param ctx
 * @param data - id голосования + вопросы-ответы
 * @returns {Promise<any>}
 * @constructor
 */
const CREATE_RESULT_FROM_USER = async (ctx, data) => {
  try {
    const url = get_url('create_result')
    const res = await API.post(url, { poll: data.id, result: data })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 *
 * @param ctx
 * @param data
 * @returns {Promise<any>}
 * @constructor
 */
const GET_GROUPS_OTHERS_PNK = async (ctx, data) => {
  try {
    const url = get_url('get_groups_others_pnk')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_CONSULTANT_BY_HASH_AND_GET_POLLS = async (ctx, hash) => {
  try {
    const url = get_url('get_consultant_by_hash_and_get_polls')
    const res = await API.get(url, { params: { hash: hash } })

    localStorage.setItem('token', res.data.token)
    localStorage.setItem('uid', res.data.consultant.id)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_CONSULTANT_BY_HASH_AND_NOT_MAILING = async (ctx, hash) => {
  try {
    const url = get_url('get_consultant_by_hash_and_not_mailing')
    const res = await API.get(url, { params: { hash: hash } })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Возвращает путь созданноого шаблона квитанции на оплату
 * @param ctx
 * @param data - сумма и объект оплаты(start, high, chlen)
 * @returns {Promise<any>}
 * @constructor
 */
const MAKE_TICKET_PAY = async (ctx, data) => {
  try {
    const url = get_url('make_ticket_pay')
    const res = await API.post(url, { amount: data.amount, way: data.way })

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
/**
 * Для архива организаций - получение групп и программ для конкретной организации
 * @param ctx
 * @returns {Promise<any>}
 * @constructor
 */
const GET_GROUPS_AND_PROGRAMS = async (ctx) => {
  try {
    const url = get_url('get_groups_and_programs')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const GET_RELEASES_CONSULTANT = async (ctx) => {
  try {
    const url = get_url('get_releases_consultant')
    const res = await API.get(url)

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const MAKE_PAYMENT_INVOICE_FOR_ORG = async (ctx, data) => {
  try {
    const url = get_url('make_payment_invoice_for_org')
    const res = await API.get(
      url,
      { params: {
        amount: data.amount,
        text: data.text,
        type_of_pay: data.type_of_pay
      } }
    )

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

const MAKE_PAYMENT_INVOICE_ORG = async (ctx, data) => {
  try {
    const url = get_url('make_payment_invoice_org')
    const res = await API.get(
      url,
      { params: { text: data.text } }
    )

    return res.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default {
  showToast,
  showError,
  showSuccess,
  GET_REGIONS,
  GET_ORGS,
  GET_PROGRAMS,
  SIGN_UP_CONSULTANT,
  FIND_CONSULTANT,
  INN_INFO,
  GET_ZAYAVLEMIYE,
  GET_INFO_PROFILE,
  USER_FILES,
  CHANGE_PASSWORD,
  UPDATE_PASSWORD,
  SIGN_IN,
  FINISH_UPLOAD,
  GET_SCHET_POVYSHENIE,
  GET_LECTORS,
  GET_LIBRARY,
  GET_MATERAIL,
  UPDATE_INDIVIDUAL,
  REGISTER,
  CONFIRM_EMAIL,
  GET_COMPANIES,
  GET_COMPANY_INFO,
  GET_CONSULTANTS,
  GET_EDUCATIONS,
  UPDATE_EDUCATION,
  GET_INFO_EDUCATION,
  GET_EDUCATION_GROUPS,
  CREATE_GROUP,
  GET_GROUP_CONSULTANTS,
  GET_ALL_CONSULTANTS,
  ADD_CONSULTANT_TO_GROUP,
  GET_ALL_PROGRAMS,
  GET_SEMINARS,
  REGISTER_TO_SEMINAR,
  GET_SEMINAR_DATA,
  LOAD_PHOTO,
  GET_ORG_GROUPS,
  CREATE_EDU_PROPOSAL,
  GET_STATUSES,
  UPDATE_GROUP,
  GET_REQUESTS,
  GET_EVENTS,
  SIGN_UP_TO_EVENT,
  GET_EVENTS_AUTH,
  REGISTER_TO_EVENT,
  GET_EVENT,
  GET_EVENT_AUTH,
  GET_PAYMENTS_HISTORY,
  GET_GROUPS_CONSULTANTS,
  GET_CONSULTANT_PROFILE_INFO,
  MOVE_CONSULTANT,
  MOVE_CONSULTANT_TO_GROUP,
  GET_ALL_GROUPS,
  GET_EDUCATION_INFO,
  GET_GROUPS,
  ADD_CONSULTANTS_EXCEL,
  CREATE_TRAJECTORY,
  GET_ADVERTS,
  ORG_FILES,
  GET_PROPOSALS_FROM_USER,
  REGISTER_ORDER,
  CHECK_ORDER,
  CHECK_ATTESTAT_EXIST,
  GET_CONSULTANTS_FROM_ROOM,
  GET_ORGANISATION_INFO,
  GET_GROUP_BY_ID,
  GET_PRICE_FOR_CHLEN,
  CHECK_PAYMENT_FROM_CONSULTANT,
  GET_CONSULTANTS_FROM_ARCHIVE,
  GET_ACTIVE_POLLS,
  CREATE_RESULT_FROM_USER,
  CREATE_EDU_PROPOSAL_VIDEO,
  GET_GROUPS_OTHERS_PNK,
  GET_CONSULTANT_BY_HASH_AND_GET_POLLS,
  GET_CONSULTANT_BY_HASH_AND_NOT_MAILING,
  MAKE_TICKET_PAY,
  GET_GROUPS_AND_PROGRAMS,
  GET_RELEASES_CONSULTANT,
  MAKE_PAYMENT_INVOICE_FOR_ORG,
  MAKE_PAYMENT_INVOICE_ORG
}
